import React, { useEffect, useState } from "react";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutForm from "./Checkout";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { ApiHook } from "../../hooks/apiHook";

const Stripe = (props) => {
  const [clientSecret, setClientSecret] = useState("");
  const [stripeData, setStripeData] = useState({
    status: false,
    username: localStorage.getItem("user"),
    userAction: "upgrade",
    email: "",
    name: "",
    phone: "",
    desc: "",
  });
  console.log(localStorage.getItem("user"));
  // const stripeIntent = ApiHook.CallStripeIntent();
  
  const stripePromise = loadStripe('pk_test_51LHkzeKD7ZhA819wxNlZjA7rJtL4QnhqxPdo0BX5QF7L2QxN9Z8X0eQi3YTuWMs5izAI02xEk88TPaMx1bHUMVqc00CDofyNSN');


  return (
    <div>
      { stripePromise && (
        <Elements stripe={stripePromise} >
          <CheckoutForm {...props}/>
        </Elements>
        

      )}
    </div>
  );
};

export default Stripe;
