import API from "../../api/api";

const callApi = async (endpoint) => {
    try {
        const response = await API.get(endpoint);
        if (response.status === 200) {
            return response?.data?.data;
        } else {
            return response?.data?.data;
        }
    } catch (error) {
        console.log(error);
        throw error;
    }
};

export const MailServices = {
    getInboxes: async (page, limit) => {
        return callApi(`inbox?page=${page}&perPage=${limit}`)
    },
    // getSend: async (page, limit) => {
    //     return callApi(`sent-mail?page=${page}&perPage=${limit}`)
    // },
    getSend: async (page,limit) => {
        return API.get(`sent-mail?page=${page}&perPage=${limit}`)
            .then((response) => response)
            .catch((error) => Promise.reject(error));
    },
    getAdmin: async (page, limit) => {
        return callApi(`inbox-from-admin?page=${page}&perPage=${limit}`)
    },
   
    getSingleMail: async (id) => {
        return callApi(`view-single-mail?mailId=${id}`)
    },
    replyMail: async (data) => {
        return API.post('reply-to-mail', JSON.stringify(data))
            .then((response) => response)
            .catch((error) => Promise.reject(error));
    },
    deleteMail:async (id) => {
        return API.post(`delete-mail?mailId=${id}`, )
            .then((response) => response)
            .catch((error) => Promise.reject(error));
    },
    mailData: async () => {
        return API.get('compose-mail-data')
            .then((response) => response)
            .catch((error) => Promise.reject(error));
    },
    mailsend: async (data) => {
        return API.post('send-internal-mail', JSON.stringify(data))
            .then((response) => response)
            .catch((error) => Promise.reject(error));
    },
}