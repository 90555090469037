import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { Button } from "react-bootstrap";
import API from "../../api/api";
import { Popover, OverlayTrigger } from "react-bootstrap";

function UserDropdown({ props }) {

  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState("replica");
  const [copied, setCopied] = useState(false);
  const moduleStatus = useSelector(
    (state) => state.dashboard?.appLayout?.moduleStatus
  );



  const progressBarRef = useRef(null);


  useEffect(() => {
    const strokeDashOffsetValue =
      100 - (props?.productValidity?.packageValidityPercentage ?? 100);
    progressBarRef.current.style.strokeDashoffset = strokeDashOffsetValue;
  }, [props?.productValidity?.packageValidityPercentage]);

  const copyToClipboard = async (text) => {
    if (navigator && navigator.clipboard) {
      try {
        await navigator.clipboard.writeText(text);
        setCopied(true);
        // Reset copied status after a delay (e.g., 2 seconds)
        setTimeout(() => {
          setCopied(false);
        }, 2000);
      } catch (error) {
        console.error("Clipboard copy failed:", error);
      }
    } else {
      // Clipboard API is not supported, provide a fallback method
      try {
        const textArea = document.createElement("textarea");
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand("copy");
        document.body.removeChild(textArea);
        setCopied(true);
        // Reset copied status after a delay (e.g., 2 seconds)
        setTimeout(() => {
          setCopied(false);
        }, 2000);
      } catch (error) {
        console.error("Fallback clipboard copy failed:", error);
      }
    }
  };

  const renderPopover = (content) => (
    <Popover>
      <Popover.Body>{`${content}`}</Popover.Body>
    </Popover>
  );



  const handleButtonClick = async () => {

    const response = await API.post("profile/upgrade");
    console.log("========== data ========");
    console.log(response.data.data);
    if (response.data.status) {
      const id = response.data.data.id;
      const token = response.data.data.token
      console.log(`https://campmotiv.com/upgrade/user/${id}/token/${token}`);
      const redirectURL = `https://campmotiv.com/upgrade/user/${id}/token/${token}`;
      window.location.href = redirectURL;
    }

  };

  const renderSocialIcons = () => {
    return (
      <React.Fragment >
        <OverlayTrigger
          trigger={["hover", "focus"]}
          placement="top"
          overlay={renderPopover(copied ? "Copied" : "Customer purchase link")}
        >
          <div
            className="replica_link_sec_ico"
            onClick={() => copyToClipboard(`https://campmotiv.com/replica/purchase/${props?.wpId}`)}
          >
            <img src={`images/copy.svg`} alt="" />

          </div>
        </OverlayTrigger>

      </React.Fragment>
    );
  };



  return (
    <>
      <aside className="left_sidebar"></aside>
      <div className="rightSide_top_user_dropdown">
        <div className="rightSide_top_user_dropdown_avatar_sec">
          <div className="rightSide_top_user_dropdown_avatar">
            <img
              src={props?.image ? props?.image : "/images/user-profile.png"}
              alt=""
            />
            {moduleStatus?.kyc_status === 1 ? (
              <div className="kyc_vrfd">
                {props?.kycStatus ? (
                  <img src="/images/kyc_vrfd.svg" alt="" />
                ) : (
                  <img src="/images/kyc_not_vrfd.png" alt="" />
                )}
              </div>
            ) : (
              <div className="kyc_vrfd">
                <img src="/images/kyc_vrfd.svg" alt="" />
              </div>
            )}
            <svg
              className="profile_progress"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="-1 -1 34 34"
            >
              <circle
                cx="16"
                cy="16"
                r="15.9155"
                className="progress-bar__background"
              />
              <circle
                cx="16"
                cy="16"
                r="15.9155"
                className="progress-bar__progress js-progress-bar"
                ref={progressBarRef}
              />
            </svg>
          </div>
        </div>
        <div className="rightSide_top_user_dropdown_nameBOx">
          <div className="rightSide_top_user_dropdown_name">
            {props?.fullname}
          </div>
          <div className="rightSide_top_user_dropdown_id">
            {props?.username}
          </div>
          {moduleStatus?.product_status === 1 && (
            <div className="rightSide_top_user_dropdown_package">
              {props?.packageName}
            </div>
          )}
        </div>
      </div>
      <div className="top_right_personal_dtl_box_sec">
        <div className="top_right_personal_dtl_box border-sprt">
          <span>{t("personalPV")}</span>
          <strong>{props?.personalPv ?? 0}</strong>
        </div>
        <div className="top_right_personal_dtl_box">
          <span>{t("groupPV")}</span>
          <strong>{props?.groupPv ?? 0}</strong>
        </div>
      </div>
      <div className="top_right_personal_dtl_box_sec">
        <div className="top_right_personal_dtl_box text-center">
          <span>{t("sponsor")}</span>
          <strong>{props?.sponsorName}</strong>
        </div>
      </div>
      <div className="top_profile_upgrade_renew_btn_sec">
        {moduleStatus?.package_upgrade === 1 && (
          <Button
            onClick={handleButtonClick}
            className="top_profile_upgrade_renew_btn_1"
          >
            {t("upgrade")}
          </Button>
        )}
        {moduleStatus?.subscription_status === 1 && (
          <NavLink
            to="/renew"
            className="top_profile_upgrade_renew_btn_1"
          >
            {t("renew")}
          </NavLink>
        )}
      </div>
     
      <div className="replica_link_sec" style={{paddingBottom:'10px'}}>
        Purchase Link
        <div className="replica_link_sec_row" >
          {renderSocialIcons(`https://campmotiv.com/replica/purchase/${props?.wpId}`)}
        </div>
      </div>
     


    </>
  );
}

export default UserDropdown;
