import React from "react";
import { useTranslation } from "react-i18next";
import { ApiHook } from "../../hooks/apiHook";
import { NavLink, useLocation } from "react-router-dom";

const MailSidebarContent = ({ setShowCompose, setMailContent, count, sendMailContent }) => {
  const { t } = useTranslation();
  const { mutate } = ApiHook.CallSendMailData();

  const handleCompose = async () => {
    setShowCompose(true);
    mutate(
      { sendMailContent },
      {
        onSuccess: (res) => {
          if (res.data.status) {
            setMailContent(res?.data?.data);
          }
        },
      }
    );
  };

  // Get the current location
  const location = useLocation();

  const navLinkItems = [
    { path: "/mailbox", icon: "fa-regular fa-envelope-open", label: t("inbox"), count: count.inbox },
    { path: "/mailbox/send", icon: "fa-regular fa-paper-plane", label: t("send") },
    { path: "/mailbox/admin-inbox", icon: "fa-solid fa-pen", label: t("admin_inbox"), count: count.admin },
  ];

  return (
    <div className="menu-bar">
      <ul className="menu-items">
        <li className="compose mb-3">
          <button
            data-bs-toggle="offcanvas"
            data-bs-target="#composemail"
            aria-controls="composemail"
            className="btn btn-primary btn-block"
            onClick={handleCompose}
          >
            {t("compose")}
          </button>
        </li>

        {navLinkItems.map((item, index) => (
          <li key={index} className={location.pathname === item.path ? "active" : ""}>
            <NavLink to={item.path}>
              <i className={item.icon}></i> {item.label}
            </NavLink>
            {/* {item.count && <span className="badge badge-pill bg-success">{item.count}</span>} */}
            <span className="badge badge-pill bg-success">{item.count}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default MailSidebarContent;
