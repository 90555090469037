


import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { BASE_URL } from '../../config/config';
import React, { useEffect, useState } from "react";
import { ApiHook } from "../../hooks/apiHook";
import { toast } from "react-toastify";
const CheckoutForm = (props) => {
  console.log(props);
  const [processing, setProcessing] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const subscriptionMutation = ApiHook.CallUpgradeStripe();
  const handleSubmit = async (event) => {
    event.preventDefault();
    let res;
    setProcessing(true);
    // setStripeError("");
    const cardElement = elements.getElement(CardElement);
    const { paymentMethod, error } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
      billing_details: {
        // email: props?.stripeData?.email,
        
      },
    });
    if (error) {
      console.log(" inside stripe error ");
      // setStripeError(error.message);
      setProcessing(false);
      return;
    }
    console.log(" === after stripe === ",localStorage.getItem("user"));
    const username = (localStorage.getItem("user"))
    const data = {
      intentId: paymentMethod?.id,
      data: {
        email: props?.stripeData?.email,
        name: props?.stripeData?.name,
        desc: props?.stripeData?.desc,
        amount: props.amount,
        product: props?.stripeData?.userAction !== 'repurchase' ? props.product :props.cart ,
      },
      username :username,
      userAction: props?.stripeData?.userAction,
    }
   let stripeResponse = "" ;
   subscriptionMutation.mutate(data, {
    onSuccess: (res) => {
      if (res.status) {
        toast.success("payment");
        stripeResponse = res ;
        // navigate("/upgrade");
      }else{
        toast.error(res?.message)
      }
    },
  });
    console.log(" === stripeResponse ===");
    console.log(stripeResponse);
    if (stripeResponse.status) {
      event.target.querySelector('button[type="submit"]').disabled = true;
      if (stripeResponse.data.requireAction) {
        const { error, paymentIntent } = await stripe.confirmCardPayment(
          stripeResponse.data.clientSecret,
          {
            payment_method: {
              card: cardElement,
            },
          }
        );
        console.log(paymentIntent);
        if (error) {
          console.log(error);
          // setStripeError(error.message);
        } else if (paymentIntent.status === "requires_action") {
          const { error: actionError, paymentIntent: updatedPaymentIntent } =
            await stripe.handleCardAction(paymentIntent.client_secret);
          if (actionError) {
            // setStripeError(actionError.message);
          } else {
            console.log(updatedPaymentIntent);
          }
        } else {
          if (paymentIntent.status === "succeeded") {
            // setStripesucess(true);
            if (
              window.location.pathname.includes("/register") ||
              window.location.pathname.includes("/replica_register")
            ) {
              props.submit(stripeResponse.data, "", "payment");
            } else {
              props.submit(stripeResponse.data, "");
            }
          } else {
            console.log(paymentIntent);
            // setStripeError("Payment failed");
          }
        }
        setProcessing(false);
      } else {
        // setStripesucess(true);
        if (
          window.location.pathname.includes("/register") ||
          window.location.pathname.includes("/replica_register")
        ) {
          props.submit(stripeResponse.data, "", "payment");
        } else {
          props.submit(stripeResponse.data, "");
        }
      }
    } else {
      setProcessing(false);
      // setStripeError(
      //   res?.error?.description
      //     ? res?.error?.description
      //     : "Something went wrong.."
      // );
    }
    event.target.querySelector('button[type="submit"]').disabled = false;
  };


  return ( <>
    <form onSubmit={handleSubmit} id="payment-form" style={{ padding: "10px", width: "80%", margin: "25px" }}>
      <div
        id="card-element"
        style={{ padding: "10px", width: "100%", margin: "16px" }}
      >
        <CardElement
          options={{
            base: {
              color: "#32325d",
              fontFamily: "Arial, sans-serif",
              fontSmoothing: "antialiased",
              fontSize: "20px",
              "::placeholder": {
                color: "#32325d",
              },
            },
            invalid: {
              fontFamily: "Arial, sans-serif",
              color: "#fa755a",
              iconColor: "#fa755a",
            },
          }}
        />
        <button
          id="submit-new"
          type="submit"
          disabled={!stripe || processing}
          style={{
            backgroundColor: "rgb(90, 85, 163)",
            borderRadius: "10px",
            color: "white",
            width: "20%",
            marginTop: "15px",
          }}
        >
          {
            <>
              {processing ? (
                <div>Processing...</div>
              ) : (
                <span id="button-text">
                  <i
                    style={{ color: "#ffffff" }}
                  ></i>{" "}
                  Pay now
                </span>
              )}
            </>
          }
        </button>
      </div>
    </form>
  </>
  )
};

export default CheckoutForm;






