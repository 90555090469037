import API from "../../api/api";

export const renewService = {
  getSubscriptionDetails: async () => {
    const response = API.get(`get-subscription-details`);
    return response;
  },
  renewSubscription: async (data) => {
    const response = API.post(`renew-subscription`, JSON.stringify(data))
    return response;
  },
  autoSubscription: async (data) => {
    const response = API.post(`paypal-autosubscription`, JSON.stringify(data))
    return response;
  },
  stripeUpgrade: async (data) => {
    const response = API.post(`stripe-subscription`, JSON.stringify(data))
    return response;
  },
};
